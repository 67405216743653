import { ROUTES } from 'src/apps/NewDriverApp/constants';

export const MENU_CATEGORY = {
  ACCIDENT: 'accident',
  DAMAGE: 'damage',
  CHECKLIST: 'checklist',
  THEFT_VANDALISM: 'theft-vandalism',
} as const;

export const MENU_DAMAGE_SUB_CATEGORY = {
  INTERIOR: 'interior',
  MECHANICAL_SERVICE_LIGHTS: 'mechanical-service-lights',
  THEFT: 'theft',
  ENVIRONMENT: 'environment',
};

export const MENU_ACCIDENT_DAMAGE_TYPE = {
  OWN_DAMAGE: 'ownDamage',
  ANOTHER_CAR_DAMAGE: 'anotherCarDamage',
  FIXED_OBJECT_DAMAGE: 'fixedObjectDamage',
  PERSON_DAMAGE: 'personDamage',
  ANIMAL_DAMAGE: 'animalDamage',
} as const;

export const MENU_ENVIRONMENT_DAMAGE_TYPE = {
  HAIL: 'hail',
  STORM: 'storm',
  FLOOD: 'flood',
} as const;

export const MENU_THEFT_VANDALISM_DAMAGE_TYPE = {
  THEFT: 'theft',
  VANDALISM: 'vandalism',
} as const;

export const DAMAGE_TYPE_QUERY_KEY = 'type';

export const MENU_PAGE_ROUTES = {
  DAMAGE: MENU_CATEGORY.DAMAGE,
  DAMAGE_ENVIRONMENTAL: `${MENU_CATEGORY.DAMAGE}/${MENU_DAMAGE_SUB_CATEGORY.ENVIRONMENT}`,
  ACCIDENT_ENVIRONMENTAL_FLOW: `${ROUTES.MENU}/${MENU_CATEGORY.DAMAGE}/${MENU_DAMAGE_SUB_CATEGORY.ENVIRONMENT}/*`,
  DAMAGE_ACCIDENT: `${MENU_CATEGORY.ACCIDENT}`,
  ACCIDENT_FLOW: `${ROUTES.MENU}/${MENU_CATEGORY.ACCIDENT}/*`,
  DAMAGE_INTERIOR: `${MENU_CATEGORY.DAMAGE}/${MENU_DAMAGE_SUB_CATEGORY.INTERIOR}`,
  CHECKLIST: MENU_CATEGORY.CHECKLIST,
  THEFT_VANDALISM: MENU_CATEGORY.THEFT_VANDALISM,
  THEFT_VANDALISM_FLOW: `${ROUTES.MENU}/${MENU_CATEGORY.THEFT_VANDALISM}/*`,
  THEFT_VANDALISM_THEFT: `${MENU_CATEGORY.THEFT_VANDALISM}/${MENU_DAMAGE_SUB_CATEGORY.THEFT}`,
};
