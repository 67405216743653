import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useIsDisableAnimation from '../../hooks/useIsDisableAnimation';
import { cameraSelector } from '../../ui-components/Camera/redux/selectors';

const savedScrollCoordinates: Record<string, number> = {};

export const useLayoutScrollPosition = (
  parentElementRef: React.MutableRefObject<HTMLDivElement | null>,
) => {
  const { isCameraOpened } = useSelector(cameraSelector);
  const location = useLocation();
  const isDisableAnimation = useIsDisableAnimation();

  useEffect(() => {
    if (
      isDisableAnimation &&
      parentElementRef.current &&
      savedScrollCoordinates[location.pathname]
    ) {
      parentElementRef.current.scrollTo(0, savedScrollCoordinates[location.pathname]);
    }

    return () => {
      if (isCameraOpened && parentElementRef.current) {
        savedScrollCoordinates[location.pathname] = parentElementRef.current.scrollTop;
      }
    };
  }, [isDisableAnimation, isCameraOpened]);
};
