import { createReducer } from '@reduxjs/toolkit';

import { AccidentDamageReasons } from 'src/api/globalTypes';
import { setDamageReason, setDamageReasonDescription } from 'src/apps/NewDriverApp/redux/actions';

export interface DamageReasonsReducer {
  reason?: AccidentDamageReasons;
  description?: string;
}

const initialState: DamageReasonsReducer = {};

export const damageReasonsReducer = createReducer(initialState, {
  [setDamageReason.type]: (state, { payload }) => {
    state.reason = payload;
  },

  [setDamageReasonDescription.type]: (state, { payload }) => {
    state.description = payload;
  },
});
