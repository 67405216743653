import React, { SyntheticEvent, forwardRef } from 'react';

import cx from 'classnames';

import styles from './ContentArea.module.scss';

type MarginTop = 0 | 8;

interface Props {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: (e: SyntheticEvent) => void;
  marginTop?: MarginTop;
}

const ContentArea = forwardRef<HTMLDivElement, Props>(
  ({ className, children, style, onClick, marginTop }, ref) => {
    const marginTopClassName = styles[`marginTop-${marginTop}`];

    return (
      <div
        ref={ref}
        onClick={onClick}
        style={style}
        className={cx(styles.container, className, marginTopClassName)}
      >
        {children}
      </div>
    );
  },
);

export { ContentArea };
