import { TFunction } from 'i18next';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

export const getLocalized = (t: TFunction, lang: string) => {
  return <T>(obj: T, key: keyof T) => {
    const objectKey = typeof key === 'string' ? key : null;

    if (!obj || !objectKey) {
      return undefined;
    }

    // key path: "keyEn"
    const keyLangTranslation = obj[`${objectKey}${capitalize(lang)}` as keyof T];
    // key path: "key_en"
    const keyUnderscoreLangTranslation = obj[`${objectKey}_${lang}` as keyof T];
    // key path: "key_i18n"
    const keyI18nTranslationPath = obj[`${objectKey}_i18n` as keyof T] as string | undefined;
    const keyI18nTranslation = keyI18nTranslationPath ? t(keyI18nTranslationPath) : undefined;
    // key path: "key"
    const keyTranslation = obj[key];

    const translation =
      keyLangTranslation || keyUnderscoreLangTranslation || keyI18nTranslation || keyTranslation;

    return typeof translation === 'string' ? translation : undefined;
  };
};

/**
 * Supports localization via
 * - camelcased suffix. eg. message -> messageEn
 * - snakecased suffix. eg message -> message_en
 * - direct value. eg message -> message
 * - localization suffix. the value by than key is then used as localization key. eg message_i18n -> t(obj[message_i18n])
 *
 * @param namespace
 * @returns
 */
export const useLocalized = (namespace?: string) => {
  const { i18n, t } = useTranslation(namespace);
  const lang = i18n.language;

  return getLocalized(t, lang);
};
