import React, { useCallback } from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DamageReportImageType } from 'src/api/globalTypes';
import { ReactComponent as EditIcon } from 'src/apps/NewDriverApp/assets/icons/px-pencil.svg';
import { i18n_namespace as i18nInteriorNamespace } from 'src/apps/NewDriverApp/flows/fleet/interior/DamagePhotos/i18n-namespace';
import {
  getPhotosForbiddenError,
  getPhotosUploadLimitExceededError,
} from 'src/apps/NewDriverApp/pages/DamagePhotos/utils';
import { ImageState, ImageUploadStatus } from 'src/apps/NewDriverApp/redux/types/Types';
import { openCamera } from 'src/apps/NewDriverApp/ui-components/Camera/redux/actions';
import { PhotoInput } from 'src/apps/NewDriverApp/ui-components/PhotoInput';
import Text from 'src/apps/NewDriverApp/ui-components/Text';
import { findDamageReportImageByType, urlToBase64 } from 'src/apps/NewDriverApp/utils/helpers';
import { useRequiredImagesContext } from 'src/apps/NewDriverApp/utils/useRequiredImagesContext';

import { MAX_IMAGES_PRE_IMAGE_BLOCK } from './constants';
import styles from './ImagesBlock.module.scss';
import { CameraSettings, SupportedImageOrder } from './types';
import { getUnTakenImageType, shouldRenderAdditionalInput } from './utils';
import { useCommonImageUtils } from '../../hooks/useCommonImageUtils';
import PhotoPlaceholder from '../../icons/PhotoPlaceholder';
import { useImageViewerImages } from '../../pages/DamageReportFlow/components/DamageCard/hooks';
import { getImageInputLabelText } from '../../utils/getImageInputLabelText';
import ImageViewer from '../ImageViewer';
import NotificationPlate from '../NotificationPlate';
import PhotoInputGrid from '../PhotoInputGrid';
import PhotoInputGridItem from '../PhotoInputGridItem';

interface Props {
  images: ImageState[];
  withDivider?: boolean;
  maxImagesNumber?: number;
  supportedImageOrder?: SupportedImageOrder[];
  listImageTypes?: DamageReportImageType[];
  cameraSettings?: CameraSettings;
  isAdditionalInputHidden?: boolean;
  isRenderAllImagesInPreview?: boolean;
  getLabelText?: (imageType: string, i: number) => string | undefined;
}

const ImagesBlock: React.FC<Props> = ({
  images,
  withDivider = true,
  maxImagesNumber,
  supportedImageOrder,
  listImageTypes,
  cameraSettings,
  isAdditionalInputHidden,
  isRenderAllImagesInPreview,
  getLabelText,
}) => {
  const { t } = useTranslation([i18nInteriorNamespace, 'camera', 'damagePhotos', 'common']);
  const dispatch = useDispatch();
  const requiredImages = useRequiredImagesContext();
  const initialImages = images.filter(image => isRenderAllImagesInPreview || !image.internalId);
  const initialImagesToRender = initialImages.slice(0, MAX_IMAGES_PRE_IMAGE_BLOCK);

  const addedImages = images.filter(
    image => !isRenderAllImagesInPreview && Boolean(image.internalId),
  );

  const {
    onClickInputHandlerWithViewer,
    isImageViewerOpen,
    currentImageIndex,
    handleImageViewerClose,
  } = useCommonImageUtils({
    customImages: initialImages,
  });

  const unTakenImageInfo = getUnTakenImageType(images, supportedImageOrder);

  const hasUploadingError = images.some(
    image => image.uploadingStatus === ImageUploadStatus.FAILURE,
  );

  const hasForbiddenUploadingError = getPhotosForbiddenError(images);
  const hasPhotosUploadLimitExceededError = getPhotosUploadLimitExceededError(images);
  const isErrorTypeWithoutLabel = hasForbiddenUploadingError || hasPhotosUploadLimitExceededError;

  const shouldDisplayAdditionalImageInput = shouldRenderAdditionalInput({
    images,
    maxImagesNumber,
    isAdditionalInputHidden,
  });

  const mappedImageViewerImages = useImageViewerImages({ images });

  const checkIsImageRequired = useCallback(({ internalId, context }: ImageState = {}) => {
    const type = get(context, 'imageType') || DamageReportImageType.ADDITIONAL;

    return (
      requiredImages &&
      get(
        requiredImages.find(
          requiredImage => requiredImage.type === type && requiredImage.internalId === internalId,
        ),
        'isRequired',
        false,
      )
    );
  }, []);

  const onCameraOpen = (selectedImage?: ImageState) => async () => {
    const { id, internalId, context, fullUrl } = selectedImage || {};

    const nextListImageType = listImageTypes
      ? listImageTypes.find(requiredType => !images.find(findDamageReportImageByType(requiredType)))
      : null;

    const type = get(context, 'imageType') || nextListImageType || DamageReportImageType.ADDITIONAL;
    const actionArgs = get(cameraSettings, `${type}.action`, {});
    const data = await urlToBase64(fullUrl);

    const isImageRequired = checkIsImageRequired({ internalId, context: { imageType: type } });

    const isDeletable = isImageRequired !== undefined && !isImageRequired;

    dispatch(
      openCamera({
        selectedPhoto: { internalId, data, context: { id, imageType: type } },
        isDeletable,
        ...actionArgs,
      }),
    );
  };

  const uploadError = (
    <NotificationPlate
      title={t('camera:errors.upload.title')}
      content={t('camera:errors.upload.description')}
      className={styles.error}
      type="error"
    />
  );

  const forbiddenUploadError = (
    <NotificationPlate
      title={t('camera:errors.forbidden.title')}
      content={t('camera:errors.forbidden.description')}
      className={styles.error}
      type="error"
    />
  );

  // These images should be squeezed when
  // the overview edit functionality will be updated
  const imageInputs = addedImages.map((image, i) => {
    const labelText = getLabelText
      ? getLabelText(image.context?.imageType, i)
      : getImageInputLabelText({ t, imageType: image.context?.imageType });

    return (
      <PhotoInputGridItem key={image.internalId}>
        <PhotoInput<ImageState>
          isOnlyExternalClickHandler
          image={image}
          isRequired={checkIsImageRequired(image)}
          onClickInputHandler={onCameraOpen(image)}
          RightIcon={EditIcon}
          labelText={labelText}
        />
      </PhotoInputGridItem>
    );
  });

  if (shouldDisplayAdditionalImageInput) {
    imageInputs.push(
      <PhotoInputGridItem key="additional-image-input">
        <PhotoInput<ImageState>
          isOnlyExternalClickHandler
          onClickInputHandler={onCameraOpen()}
          isRequired={unTakenImageInfo.isRequired}
          isDisplayErrorOutline={unTakenImageInfo.isRequired}
          labelText={t('common:addPhoto')}
          placeholder={PhotoPlaceholder}
        />
      </PhotoInputGridItem>,
    );
  }

  return (
    <div className={cn({ [styles.withDivider]: withDivider, [styles.container]: !withDivider })}>
      {hasUploadingError && !isErrorTypeWithoutLabel && uploadError}
      {hasForbiddenUploadingError && forbiddenUploadError}

      <PhotoInputGrid>
        {initialImagesToRender.map((image, i, arr) => {
          const squeezedImagesQuantity = initialImages.length - arr.length;

          const isOverlayEnabled =
            squeezedImagesQuantity > 0 && i + 1 === MAX_IMAGES_PRE_IMAGE_BLOCK;

          const labelText = getLabelText
            ? getLabelText(image.context?.imageType, i)
            : getImageInputLabelText({ t, imageType: image.context?.imageType });

          return (
            <PhotoInputGridItem key={image.id}>
              <PhotoInput<ImageState>
                isOnlyExternalClickHandler
                image={image}
                onClickInputHandler={onClickInputHandlerWithViewer(image.context?.imageType)}
                isOverlayEnabled={isOverlayEnabled}
                overlayContent={
                  <Text
                    type="h1"
                    color="primary-background-base"
                  >{`+${squeezedImagesQuantity}`}</Text>
                }
                labelText={labelText}
              />
            </PhotoInputGridItem>
          );
        })}

        {imageInputs}
      </PhotoInputGrid>

      {isImageViewerOpen && (
        <ImageViewer
          isOpen={isImageViewerOpen}
          startIndex={currentImageIndex}
          onClose={handleImageViewerClose}
          slides={mappedImageViewerImages}
          isWithZoom
        />
      )}
    </div>
  );
};

export default ImagesBlock;
