import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { InjuredPersonInformationInput } from 'src/api/globalTypes';

import { accidentInjuredPersonInformationPageCompleted } from '../actions';

const initialState: InjuredPersonInformationInput = {
  insuranceCompany: '',
  fullName: '',
  email: '',
  phone: '',
  comment: '',
};

export const accidentInjuredPersonInformationReducer = createReducer(initialState, {
  [accidentInjuredPersonInformationPageCompleted.type]: (
    state,
    action: PayloadAction<InjuredPersonInformationInput>,
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },
});
