import { createReducer } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import mergeWith from 'lodash/mergeWith';
import unset from 'lodash/unset';

import { WizardContext } from '../../containers/Wizard/types';
import { resetWizardData, setWizardData } from '../actions';

const setWizardDataCustomizer = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return srcValue;
  }

  return undefined;
};

type WizardReducer = Record<string, WizardContext>;

const initializedWizardData: WizardContext = {
  data: {},
  completed: [],
  isNavigating: false,
  isCompleted: false,
  goToRouteId: null,
  currentActiveRouteIndex: null,
  isFirstRoute: false,
  isLastRoute: false,
  routeCount: null,
  error: null,
};

const initialState: WizardReducer = {};

export const wizardsReducer = createReducer<WizardReducer>(initialState, {
  [setWizardData.type]: (state, action) => {
    const isPreviousStateEmpty = isEmpty(state[action.payload.name]);
    const previousState = isPreviousStateEmpty ? initializedWizardData : state[action.payload.name];

    return {
      ...state,
      [action.payload.name]: mergeWith(
        cloneDeep(previousState),
        action.payload.data,
        setWizardDataCustomizer,
      ),
    };
  },
  [resetWizardData.type]: (state, action) => {
    const clonedState = cloneDeep(state);
    unset(clonedState, action.payload);

    return clonedState;
  },
});
