import React, {
  ChangeEvent,
  SyntheticEvent,
  useState,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { ImageUploadStatus } from 'src/apps/NewDriverApp/redux/types/Types';
import InfiniteLoader from 'src/apps/NewDriverApp/ui-components/InfiniteLoader/InfiniteLoader';
import getCssVariableValue from 'src/apps/NewDriverApp/utils/getCssVariableValue';
import { toBase64 } from 'src/services/image/encoder';

import PhotoInputEditIcon from './components/PhotoInputIconPlaceholder';
import styles from './PhotoInput.module.scss';
import { ImageStateConstraint, PhotoInputRef } from './types';
import { getPlaceholderProps, getProgressProps } from './utils';
import loadAgain from '../../assets/icons/px-load-again.svg';
import { ImageMimeTypes, SUPPORTED_FORMATS } from '../../constants';
import CrossOutEye from '../../icons/CrossOutEye';
import Eye from '../../icons/Eye';
import { InputIconProps } from '../../types';
import CircularProgress from '../CircularProgress';
import Text from '../Text';

interface Props<ImageState> {
  id?: string;
  className?: string;
  labelText?: string;
  image?: ImageState;
  supportedImageFormats?: ImageMimeTypes[];
  placeholder?: React.FunctionComponent<InputIconProps>;
  onRightIconClick?: (e: SyntheticEvent, image?: ImageState) => void;
  onNotAllowedFormatSelected?: () => void;
  onPhotoSelected?: (e: ChangeEvent<HTMLInputElement>, image: ImageState) => void;
  onClickInputHandler?: (e: SyntheticEvent, image?: ImageState) => void;
  RightIcon?: React.FunctionComponent;
  isOnlyExternalClickHandler?: boolean;
  isRequired?: boolean;
  isDisplayErrorOutline?: boolean;
  isOverlayEnabled?: boolean;
  resetError?: boolean;
  displayImageVisibilityIndicator?: boolean;
  overlayContent?: React.ReactNode;
}

const PhotoInput = <ImageState extends ImageStateConstraint>(
  {
    id,
    className,
    labelText,
    image,
    supportedImageFormats,
    placeholder: Placeholder,
    onRightIconClick,
    onNotAllowedFormatSelected,
    onPhotoSelected,
    onClickInputHandler,
    RightIcon,
    isOnlyExternalClickHandler,
    isRequired,
    isDisplayErrorOutline,
    overlayContent,
    isOverlayEnabled,
    resetError = false,
    displayImageVisibilityIndicator = false,
  }: Props<ImageState>,
  ref: React.Ref<PhotoInputRef>,
) => {
  const { t } = useTranslation('camera');
  const showPlaceholder = !image;
  const fileInputRef = React.createRef<HTMLLabelElement>();
  const uploadingStatus = get(image, 'uploadingStatus');
  const isImageLoading = uploadingStatus === ImageUploadStatus.STARTED;
  const inputId = [id, image && image.internalId].find(Boolean);
  const supportedFormats = supportedImageFormats || SUPPORTED_FORMATS;
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const isDisplayLabelText = !!labelText;
  const backgroundLineColor = useMemo(() => getCssVariableValue('--secondary-state-disabled'), []);

  const uploadStatuses = {
    [ImageUploadStatus.SUCCESS]: t('camera:photos.done'),
    [ImageUploadStatus.FAILURE]: <img src={loadAgain} alt="Try again" />,
  };

  const imageUploadingStatus = uploadingStatus
    ? uploadStatuses[uploadingStatus as keyof typeof uploadStatuses]
    : null;

  const [imageHasError, setImageHasError] = useState(
    imageUploadingStatus === ImageUploadStatus.FAILURE,
  );

  const isImageLoaded = uploadingStatus === ImageUploadStatus.SUCCESS;

  useEffect(() => {
    if (image && image.uploadingStatus) {
      setImageHasError(image.uploadingStatus === ImageUploadStatus.FAILURE);
    }
  }, [image]);

  useEffect(() => {
    if (resetError) {
      setImageHasError(false);
    }
  }, [resetError]);

  const openLocalFilesInputSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInputOnClick = (e: SyntheticEvent) => {
    e.preventDefault();

    if (onClickInputHandler) {
      const selectedImage = image;

      onClickInputHandler(e, selectedImage);
    }
  };

  const savePhotoFS = async (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const selectedImage = event.target.files && event.target.files[0];
    setImageHasError(false);

    if (selectedImage && !supportedFormats.includes(selectedImage.type as ImageMimeTypes)) {
      setImageHasError(true);

      if (onNotAllowedFormatSelected) {
        onNotAllowedFormatSelected();
      }

      return;
    }

    if (selectedImage) {
      const base64SelectedImage = await toBase64(selectedImage);

      if (onPhotoSelected) {
        onPhotoSelected(event, {
          data: base64SelectedImage,
          id: get(image, 'id'),
          internalId: get(image, 'internalId'),
        } as ImageState);
      }
    }
  };

  const openInputHandler = (e: SyntheticEvent) => {
    if (isOnlyExternalClickHandler) {
      e.preventDefault();
      handleInputOnClick(e);
    }
  };

  const onLoad = () => setIsDataLoaded(true);

  const rightIconClickHandler = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (onRightIconClick) {
      e.preventDefault();
      onRightIconClick(e, image);
    }
  };

  useImperativeHandle(ref, () => ({
    emitOnClick: openLocalFilesInputSelector,
  }));

  const ImageVisibilityIndicator = ({
    image: { isVisibilityRestricted },
  }: {
    image: ImageState;
  }) => {
    return (
      <span className={styles.visibilityIndicator}>
        {isVisibilityRestricted ? (
          <CrossOutEye className={styles.icon} />
        ) : (
          <Eye className={styles.icon} />
        )}
      </span>
    );
  };

  return (
    <div
      data-selector={`PhotoInput-${uploadingStatus}`}
      className={cn(styles.wrapperBase, styles.wrapper, className, {
        [styles.disabledPointerEvents]: isImageLoading,
      })}
    >
      <figure className={styles.container}>
        <div
          className={cn(styles.borderArea, {
            [styles.errorBorder]: imageHasError || isDisplayErrorOutline,
          })}
        >
          <label
            className={styles.labelContainer}
            onClick={openInputHandler}
            htmlFor={inputId}
            ref={fileInputRef}
          >
            <div className={styles.labelCommon}>
              <input
                type="file"
                onChange={savePhotoFS}
                id={inputId}
                className={styles.input}
                accept={supportedFormats.join(',')}
              />
              {!imageHasError && showPlaceholder && Placeholder && (
                <Placeholder {...getPlaceholderProps()} />
              )}

              {!!image && !isImageLoading && (
                <>
                  <div
                    className={styles.previewWrapper}
                    data-selector={`PhotoInputImage-${uploadingStatus}`}
                  >
                    <img
                      className={styles.preview}
                      src={image.fullUrl}
                      alt="Preview"
                      onLoad={onLoad}
                    />
                    {displayImageVisibilityIndicator && <ImageVisibilityIndicator image={image} />}
                  </div>
                  {!isDataLoaded && !imageHasError && (
                    <div className={styles.loader}>
                      <InfiniteLoader />
                    </div>
                  )}
                </>
              )}

              {!imageHasError && isOverlayEnabled && (
                <div className={styles.overlayCommon}>{overlayContent}</div>
              )}

              {RightIcon && isImageLoaded && (
                <PhotoInputEditIcon className={styles.rightIcon} onClick={rightIconClickHandler}>
                  <RightIcon />
                </PhotoInputEditIcon>
              )}

              {imageHasError && (
                <div className={styles.overlayCommon}>
                  <img src={loadAgain} alt="Try again" />
                </div>
              )}

              {isImageLoading && (
                <div className={styles.progressContainer}>
                  <CircularProgress
                    {...getProgressProps({
                      backgroundLineColor,
                      progress: image ? image.uploadProgress : 0,
                    })}
                  />
                </div>
              )}
            </div>

            {isDisplayLabelText && (
              <Text
                type="link-medium"
                color="undefined"
                className={cn(styles.labelText, styles.labelNoUnderlineText)}
              >
                <span
                  className={cn(styles.truncate, {
                    [styles.labelUnderlineText]: !image,
                  })}
                >
                  {labelText}
                  {isRequired && <span className={styles.required}>*</span>}
                </span>
              </Text>
            )}
          </label>
        </div>
      </figure>
    </div>
  );
};

const PhotoInputWithRefForwarding = React.forwardRef(PhotoInput) as <T>(
  props: Props<T> & { ref?: React.Ref<PhotoInputRef> },
) => ReturnType<typeof PhotoInput>;

export { PhotoInputWithRefForwarding as PhotoInput };
