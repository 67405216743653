import { TFunction } from 'i18next';

import CrossOutEye from 'src/apps/NewDriverApp/icons/CrossOutEye';
import Eye from 'src/apps/NewDriverApp/icons/Eye/Eye';

import { VisibilityStatus } from './types';

export const getVisibilityOption = (t: TFunction, isVisibilityRestricted?: boolean | null) => {
  if (isVisibilityRestricted) {
    return {
      title: t(`damage_photos.${VisibilityStatus.HIDDEN}`),
      icon: CrossOutEye,
    };
  }

  return {
    title: t(`damage_photos.${VisibilityStatus.PUBLIC}`),
    icon: Eye,
  };
};

export const getVisibilityOptions = (t: TFunction) => {
  return [
    {
      title: t(`damage_photos.${VisibilityStatus.PUBLIC}`),
      icon: Eye,
      visibilityStatus: VisibilityStatus.PUBLIC,
    },
    {
      title: t(`damage_photos.${VisibilityStatus.HIDDEN}`),
      icon: CrossOutEye,
      visibilityStatus: VisibilityStatus.HIDDEN,
    },
  ];
};
