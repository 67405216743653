import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { BottomModal } from 'src/apps/NewDriverApp/ui-components/BottomModal';
import { ImageViewerImage } from 'src/apps/NewDriverApp/ui-components/ImageViewer/types';

import ImageVisibilitySelector from './components/ImagesVisibilitySelector/ImageVisibilitySelector';
import ImageVisibilityStatus from './components/ImageVisibilityStatus';
import styles from './ImageVisibility.module.scss';
import { i18n_namespace } from '../../i18n-namespace';

interface Props {
  currentPhoto: ImageViewerImage;
  onImageVisibilityUpdate: (image: ImageViewerImage, isVisibilityRestricted: boolean) => void;
}

const ImageVisibility: React.FC<Props> = ({ currentPhoto, onImageVisibilityUpdate }) => {
  const { t } = useTranslation(i18n_namespace);

  const [openModal, setOpenModal] = useState(false);
  const { isVisibilityRestricted } = currentPhoto;

  const onChange = (selectedIsVisibilityRestricted: boolean) => {
    if (onImageVisibilityUpdate) {
      onImageVisibilityUpdate(currentPhoto, selectedIsVisibilityRestricted);
      setOpenModal(false);
    }
  };

  return (
    <>
      <ImageVisibilityStatus
        isVisibilityRestricted={isVisibilityRestricted}
        onOpen={() => setOpenModal(true)}
      />

      <BottomModal
        className={styles.modal}
        title={t('visibility.photo')}
        onClose={() => setOpenModal(false)}
        isOpen={openModal}
      >
        <ImageVisibilitySelector
          isVisibilityRestricted={isVisibilityRestricted}
          onChange={onChange}
        />
      </BottomModal>
    </>
  );
};

export default ImageVisibility;
