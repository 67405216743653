import get from 'lodash/get';

import { DamageReportImageType } from 'src/api/globalTypes';
import { ImageState } from 'src/apps/NewDriverApp/redux/types/Types';

import { SupportedImageOrder } from './types';
import { PHOTO_FLOW_MAX_PHOTO_QUANTITY } from '../../constants';
import { findDamageReportImageByType } from '../../utils/helpers';

const DEFAULT_VALUE: SupportedImageOrder = {
  type: DamageReportImageType.ADDITIONAL,
};

export const getUnTakenImageType = (
  images: ImageState[],
  supportedImageTypesOrder?: SupportedImageOrder[],
): SupportedImageOrder => {
  if (!supportedImageTypesOrder) {
    return DEFAULT_VALUE;
  }

  const unTakenImageType = supportedImageTypesOrder.find(
    item =>
      !images.find(image => {
        const imageType = get(image, 'context.imageType');

        return imageType === item.type;
      }),
  );

  return unTakenImageType || DEFAULT_VALUE;
};

export const shouldRenderAdditionalInput = ({
  images,
  maxImagesNumber,
  isAdditionalInputHidden,
}: {
  images: ImageState[];
  maxImagesNumber?: number;
  isAdditionalInputHidden?: boolean;
}) => {
  if (isAdditionalInputHidden) {
    return false;
  }

  if (maxImagesNumber) {
    return images.length < maxImagesNumber;
  }

  return (
    images.filter(findDamageReportImageByType(DamageReportImageType.ADDITIONAL)).length <
    PHOTO_FLOW_MAX_PHOTO_QUANTITY.ADDITIONAL_PHOTOS
  );
};
