import React from 'react';

interface Props {
  className?: string;
}

const ArrowDown: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3447 0.143073C11.5522 0.343291 11.5517 0.67519 11.3436 0.874797L6.00213 5.99945L5.98178 5.97982L5.96057 6.00029L0.655106 0.874622C0.448228 0.674755 0.448316 0.343798 0.655301 0.144041C0.853065 -0.0468184 1.16703 -0.0467353 1.36469 0.144228L5.9834 4.60641L10.6367 0.142031C10.8345 -0.0477468 11.1475 -0.0472863 11.3447 0.143073Z"
        fill="#CBCCD6"
      />
    </svg>
  );
};

export default ArrowDown;
