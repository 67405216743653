export const getPlaceholderProps = () => {
  const common = {
    width: '32px',
    height: '32px',
  };

  return common;
};

export const getProgressProps = ({
  backgroundLineColor,
  progress,
}: {
  backgroundLineColor: string;
  progress?: number;
}) => {
  return {
    size: 1.4,
    lineWidth: 3,
    backgroundLineColor,
    progress: progress ?? 0,
  };
};
