export const FeatureFlags = {
  DEMO_FEATURE: 'demo-feature',
  NEW_OTHER_ACCIDENT_PARTY: 'new-other-accident-party',
  WILDLIFE_PROTECTION: 'wildlife-protection',
  COLLISION_WITH_FIXED_OBJECT: 'collision-with-fixed-object',
  CAR_OVERVIEW_FOUR_PHOTOS: 'car-overview-four-photos',
  COLLISION_WITH_PERSON: 'collision-with-person',
  NEW_MENU: 'new-menu',
  THEFT_VANDALISM: 'theft-vandalism',
  HAIL_FLOW: 'hail-flow',
  STORM_FLOW: 'storm-flow',
  FLOOD_FLOW: 'flood-flow',
} as const;

export type FeatureFlags = (typeof FeatureFlags)[keyof typeof FeatureFlags];
