import React from 'react';

import { IconProps } from 'src/apps/NewDriverApp/types';
import Text from 'src/apps/NewDriverApp/ui-components/Text';
import { ReactComponent as PxConfirmIcon } from 'src/img/other-icons/px-confirm.svg';

import styles from './ImageVisibilityOption.module.scss';

interface Props {
  title: string;
  isChecked: boolean;
  Icon: React.FC<IconProps>;
  onClick: () => void;
}

const ImageVisibilityOption: React.FC<Props> = ({ title, isChecked, onClick, Icon }) => {
  return (
    <div className={styles.listGroupItem}>
      <button type="button" className={styles.listGroupItemAction} onClick={onClick}>
        <div className={styles.listGroupItemBody}>
          <Icon className={styles.icon} />
          <Text className={styles.text}>{title}</Text>
        </div>
        {isChecked && (
          <div className={styles.isChecked}>
            <PxConfirmIcon className={styles.confirmIcon} />
          </div>
        )}
      </button>
    </div>
  );
};

export default ImageVisibilityOption;
