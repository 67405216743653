import React from 'react';

import { useTranslation } from 'react-i18next';

import ArrowDown from 'src/apps/NewDriverApp/icons/ArrowDown';

import styles from './ImageVisibilityStatus.module.scss';
import { i18n_namespace } from '../../../../i18n-namespace';
import { getVisibilityOption } from '../utils';

interface Props {
  onOpen?: () => void;
  isVisibilityRestricted?: boolean | null;
}

const ImageVisibilityStatus: React.FC<Props> = ({ isVisibilityRestricted, onOpen }) => {
  const { t } = useTranslation(i18n_namespace);
  const currentViewVisibility = getVisibilityOption(t, isVisibilityRestricted);

  const { title, icon: Icon } = currentViewVisibility;

  return (
    <div className={styles.container} onClick={onOpen}>
      <Icon className={styles.icon} />
      <span className={styles.description}>{title}</span>
      <ArrowDown />
    </div>
  );
};

export default ImageVisibilityStatus;
