import { useSelector } from 'react-redux';
import { NavigationType, useNavigationType } from 'react-router-dom';

import { getWasLastNavigationCameraOpened } from '../../redux/selectors';

const useIsDisableAnimation = () => {
  const navType: NavigationType = useNavigationType();
  const isBackNavigation = navType === NavigationType.Pop;
  const wasLastNavigationCameraOpened = useSelector(getWasLastNavigationCameraOpened);
  const isDisableAnimation = wasLastNavigationCameraOpened && isBackNavigation;

  return isDisableAnimation;
};

export default useIsDisableAnimation;
