import { ChangeEvent, SyntheticEvent, useCallback, useMemo, useState } from 'react';

import compact from 'lodash/compact';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import { useDispatch, useSelector } from 'react-redux';

import { isPC } from 'src/constants';

import useGetCurrentImageFlowData from './useGetCurrentImageFlowData';
import { ImageTypes } from '../constants';
import { removeImage } from '../redux/actions';
import { reportSelector } from '../redux/selectors';
import { ImageFromCamera, ImageState } from '../redux/types/Types';
import { DamagePhotosCameraContext } from '../types';
import {
  imageFromInputSelected,
  openCamera,
  removeCameraPhoto,
} from '../ui-components/Camera/redux/actions';
import { cameraSelector } from '../ui-components/Camera/redux/selectors';
import { ImageViewerImage } from '../ui-components/ImageViewer/types';
import { findDamageReportImageByType } from '../utils/helpers';

const findImageIndex = (images: ImageState[], image?: ImageState) => {
  return images.findIndex(({ internalId, id }) => {
    return !!intersection(compact([id, internalId]), compact([image?.id, image?.internalId]))
      .length;
  });
};
interface UseCommonImageUtilsArg {
  customImages?: ImageState[] | null;
  imageTypes?: ImageTypes[];
  isLocalDeletionOnly?: boolean;
  isPreview?: boolean;
  viewerFlows?: ImageTypes[][];
  savePhotoFSAction?: (selectedPhoto: ImageFromCamera<any>) => void;
}

export const useCommonImageUtils = ({
  customImages,
  imageTypes,
  isLocalDeletionOnly,
  isPreview,
  viewerFlows,
  savePhotoFSAction = imageFromInputSelected,
}: UseCommonImageUtilsArg) => {
  const dispatch = useDispatch();

  const { selectedPhoto } = useSelector(cameraSelector);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [currentViewerImage, setCurrentViewerImage] = useState<ImageState | null>(null);
  const { images: stateImages } = useSelector(reportSelector);
  const allImages = customImages || stateImages;

  const viewerImageImageTypes = useMemo(() => {
    return isImageViewerOpen
      ? viewerFlows?.find(iTypes => iTypes.includes(currentViewerImage?.context?.imageType))
      : undefined;
  }, [viewerFlows, currentViewerImage]);

  const supportedImageTypes = viewerImageImageTypes || imageTypes;

  const images = useMemo(
    () => allImages.filter(findDamageReportImageByType(supportedImageTypes)),
    [allImages, supportedImageTypes],
  );

  const cameraContext = get(selectedPhoto, 'context', {}) as DamagePhotosCameraContext;
  const { imageType } = cameraContext;
  const currentFlow = useGetCurrentImageFlowData();
  const isOnlyExternalClickHandler = !isPC;
  const currentImageIndex = currentViewerImage ? findImageIndex(images, currentViewerImage) : 0;

  const savePhotoFS =
    (photoType?: string) =>
    (_event: ChangeEvent<HTMLInputElement>, { data, internalId, context }: ImageState) => {
      const type = photoType || imageType;

      if (data) {
        dispatch(
          savePhotoFSAction({
            data,
            internalId,
            context: { ...context, imageType: type, isLocalDeletionOnly },
          }),
        );
      }
    };

  const deletePhoto = (image?: ImageState) => {
    if (!image) {
      return undefined;
    }
    if (isLocalDeletionOnly) {
      return dispatch(removeImage({ internalId: image.internalId as string }));
    }
    return dispatch(removeCameraPhoto({ selectedPhoto: image }));
  };

  const findImage = (image?: ImageState) => {
    return images.find(
      ({ id, internalId }) =>
        !!intersection(compact([id, internalId]), compact([image?.id, image?.internalId])).length,
    );
  };

  const handleImageViewerClose = useCallback(() => setIsImageViewerOpen(false), []);

  const onClickInputHandlerWithViewer =
    (photoType: string, isRotationLock?: boolean) => (_e?: SyntheticEvent, image?: ImageState) => {
      if (image || (image && isPreview)) {
        if (image) {
          setCurrentViewerImage(image);
          setIsImageViewerOpen(true);
        }
      } else {
        dispatch(
          openCamera({
            isRotationLock,
            selectedPhoto: { context: { imageType: photoType } },
          }),
        );
      }
    };

  const onInputDeletePhoto = (_e: SyntheticEvent, image?: ImageState) => {
    deletePhoto(image);
  };

  const onViewerDeletePhoto = useCallback(
    (image: ImageViewerImage) => {
      const imageToDelete = findImage(image);

      handleImageViewerClose();
      deletePhoto(imageToDelete);
    },
    [images],
  );

  const onViewerRetakePhoto = useCallback(
    (isRotationLock?: boolean) => (image: ImageViewerImage) => {
      const imageToRetake = findImage(image) as ImageState<DamagePhotosCameraContext>;

      if (imageToRetake) {
        dispatch(
          openCamera({
            isRotationLock,
            selectedPhoto: { context: imageToRetake.context },
          }),
        );

        handleImageViewerClose();
        deletePhoto(imageToRetake);
      }
    },
    [images],
  );

  return {
    images,
    savePhotoFS,
    deletePhoto,
    currentImageIndex,
    isImageViewerOpen,
    handleImageViewerClose,
    onClickInputHandlerWithViewer,
    onInputDeletePhoto,
    onViewerDeletePhoto,
    onViewerRetakePhoto,
    currentFlow,
    cameraContext,
    imageType,
    isOnlyExternalClickHandler,
    currentViewerImage,
  };
};
