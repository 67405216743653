import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import Modal from 'react-modal';
import Siema from 'siema';

import { useScreenResize } from 'src/apps/NewDriverApp/hooks';
import CloseImageViewer from 'src/apps/NewDriverApp/icons/CloseImageViewer';
import NavigationArrow from 'src/apps/NewDriverApp/icons/NavigationArrow';
import { Direction } from 'src/apps/NewDriverApp/icons/NavigationArrow/types';
import { DamageImageSlider } from 'src/apps/NewDriverApp/pages/ReportedDamage/components';
import { DEFAULT_START_INDEX } from 'src/apps/NewDriverApp/ui-components/ImageViewer/constants';
import { ImageViewerImage } from 'src/apps/NewDriverApp/ui-components/ImageViewer/types';

import styles from './ImageViewer.module.scss';
import ImageVisibility from '../../pages/DamageReportFlow/components/ImageVisibility/ImageVisibility';
import Text from '../Text';

interface Props {
  isOpen: boolean;
  slides: ImageViewerImage[];
  onClose: () => void;
  startIndex?: number;
  leftActionButtonIcon?: React.ReactElement;
  leftActionButtonAction?: (image: ImageViewerImage) => void;
  rightActionButtonIcon?: React.ReactElement;
  rightActionButtonAction?: (image: ImageViewerImage) => void;
  isWithZoom?: boolean;
  onImageVisibilityUpdate?: (image: ImageViewerImage, isVisibilityRestricted: boolean) => void;
  onChange?: (image: ImageViewerImage) => void;
}

const ImageViewer: React.FC<Props> = ({
  isOpen,
  slides,
  onClose,
  leftActionButtonIcon,
  leftActionButtonAction,
  rightActionButtonIcon,
  rightActionButtonAction,
  isWithZoom = false,
  onImageVisibilityUpdate,
  onChange,
  startIndex = DEFAULT_START_INDEX,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(DEFAULT_START_INDEX);
  const [imageSliderKey, setImageSliderKey] = useState<number>(Date.now());
  const siemaRef = useRef<Siema>(null);
  const isRightButtonDisabled = slides.length - 1 === currentIndex;
  const isLeftButtonDisabled = currentIndex === 0;

  const currentSlideItem = slides[currentIndex];
  const slideId = currentSlideItem?.id;

  const setNewKey = () => {
    setImageSliderKey(Date.now());
  };

  useScreenResize(setNewKey);

  const nextSlide = useCallback(() => {
    if (siemaRef.current) {
      siemaRef.current.next();
    }
  }, []);

  const prevSlide = useCallback(() => {
    if (siemaRef.current) {
      siemaRef.current.prev();
    }
  }, []);

  const counter = `${currentIndex + 1} / ${slides.length}`;

  const navigationControls = slides.length > 0 && (
    <>
      {!isLeftButtonDisabled && (
        <button className={cx(styles.navigationButton, styles.left)} onClick={prevSlide}>
          <NavigationArrow direction={Direction.Left} />
        </button>
      )}
      <Text color="secondary-border-con-low" className={styles.counter}>
        {counter}
      </Text>
      {!isRightButtonDisabled && (
        <button className={cx(styles.navigationButton, styles.right)} onClick={nextSlide}>
          <NavigationArrow direction={Direction.Right} />
        </button>
      )}
    </>
  );

  const actionButtons = useMemo(() => {
    return (
      <>
        {leftActionButtonIcon && leftActionButtonAction && (
          <button
            className={cx(styles.buttonContainer, styles.leftActionButtonContainer)}
            onClick={() => leftActionButtonAction(currentSlideItem)}
          >
            {leftActionButtonIcon}
          </button>
        )}
        {rightActionButtonIcon && rightActionButtonAction && (
          <button
            className={cx(styles.buttonContainer, styles.rightActionButtonContainer)}
            onClick={() => rightActionButtonAction(currentSlideItem)}
          >
            {rightActionButtonIcon}
          </button>
        )}
      </>
    );
  }, [
    leftActionButtonIcon,
    leftActionButtonAction,
    rightActionButtonIcon,
    rightActionButtonAction,
    currentSlideItem,
  ]);

  useEffect(() => {
    setCurrentIndex(startIndex);
  }, [startIndex]);

  useEffect(() => {
    if (onChange) {
      onChange(currentSlideItem);
    }
  }, [slideId]);

  return (
    <Modal isOpen={isOpen} overlayClassName={styles.modalOverlay} className={styles.modalContent}>
      <div
        className={cx(styles.headerContainer, { [styles.withVisibility]: onImageVisibilityUpdate })}
      >
        <Text type="body-middle" color="secondary-state-disabled" className={styles.description}>
          {currentSlideItem?.description}
          {currentSlideItem?.isRequired && (
            <Text type="body-middle" color="notification-error" className={styles.asterisk}>
              *
            </Text>
          )}
        </Text>
        <button className={cx(styles.buttonContainer, styles.closeIconContainer)} onClick={onClose}>
          <CloseImageViewer className={styles.closeIcon} />
        </button>
      </div>
      {onImageVisibilityUpdate && (
        <ImageVisibility
          onImageVisibilityUpdate={onImageVisibilityUpdate}
          currentPhoto={currentSlideItem}
        />
      )}
      <div className={styles.sliderContainer}>
        <div className={styles.sliderAligner}>
          <DamageImageSlider
            // the key is needed for appropriate slider reinitialization after the screen width was changed
            key={imageSliderKey}
            slides={slides}
            ref={siemaRef}
            index={currentIndex}
            onChange={setCurrentIndex}
            sliderItemClassName={styles.sliderItem}
            isWithZoom={isWithZoom}
          />
        </div>
      </div>
      <div className={styles.footerContainer}>
        {actionButtons}
        <div className={styles.navigationControlsContainer}>{navigationControls}</div>
        <div className={styles.createdAtContainer}>
          <Text type="body-middle" color="secondary-border-con-low">
            {currentSlideItem?.createdAt}
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ImageViewer);
