import React from 'react';

import { InputIconProps } from '../../types';

const PhotoPlaceholder: React.FC<InputIconProps> = ({ width = '31', height = '29' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3333 5.76471H30M15.5 2.58824H12.2778C11.8505 2.58824 11.4407 2.75557 11.1386 3.05342C10.8364 3.35127 10.6667 3.75524 10.6667 4.17647C10.6667 5.01892 10.3272 5.82687 9.7229 6.42257C9.11862 7.01828 8.29903 7.35294 7.44444 7.35294H4.22222C3.36764 7.35294 2.54805 7.6876 1.94377 8.28331C1.33948 8.87901 1 9.68696 1 10.5294V24.8235C1 25.666 1.33948 26.4739 1.94377 27.0696C2.54805 27.6653 3.36764 28 4.22222 28H26.7778C27.6324 28 28.4519 27.6653 29.0562 27.0696C29.6605 26.4739 30 25.666 30 24.8235V13.7059M25.1667 1V10.5294M20.3333 16.8824C20.3333 19.5138 18.1694 21.6471 15.5 21.6471C12.8306 21.6471 10.6667 19.5138 10.6667 16.8824C10.6667 14.2509 12.8306 12.1176 15.5 12.1176C18.1694 12.1176 20.3333 14.2509 20.3333 16.8824Z"
        stroke="#6B6E85"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhotoPlaceholder;
