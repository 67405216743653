import React from 'react';

import cx from 'classnames';

import styles from './NavigationArrow.module.scss';
import { Direction } from './types';

interface Props {
  direction: Direction;
  className?: string;
}

const NavigationArrow: React.FC<Props> = ({ direction, className }) => {
  return (
    <svg
      className={cx(className, { [styles.right]: direction === Direction.Right })}
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1L1 9L9 17"
        stroke="#CBCCD6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavigationArrow;
