import { createAction } from '@reduxjs/toolkit';

import {
  CompleteAccidentReportInput,
  AccidentResponsibilityCauser,
  DamageReportDrivableType,
  DamageReportWildlifeDataInput,
  InjuredPersonInformationInput,
} from 'src/api/globalTypes';

import { OtherAccidentPartyReducerData } from './reducers/other-accident-party-reducer';
import {
  AccidentAdditionalQuestionsReducer,
  AccidentDriverInformationReducer,
  AccidentPoliceReportReducer,
  AccidentSceneReducer,
  DamageReportFixedObjectReducer,
} from './types';

export const setIsCarRoadworthy =
  createAction<Partial<DamageReportDrivableType>>('SET_IS_CAR_ROADWORTHY');

export const setIsCarPrototype = createAction<boolean>('SET_IS_CAR_PROTOTYPE');

export const updateDamagedObjectData = createAction<DamageReportFixedObjectReducer>(
  'UPDATE_DAMAGED_OBJECT_DATA',
);

// accident scene page
export const accidentScenePageCompleted = createAction<AccidentSceneReducer>(
  'ACCIDENT_SCENE_PAGE_COMPLETED',
);

// accident police report page

export const accidentPoliceReportPageCompleted = createAction<AccidentPoliceReportReducer>(
  'ACCIDENT_POLICE_REPORT_PAGE_COMPLETED',
);

// accident injured person information page

export const accidentInjuredPersonInformationPageCompleted =
  createAction<InjuredPersonInformationInput>('ACCIDENT_INJURED_PERSON_INFORMATION_PAGE_COMPLETED');

// accident responsibility page

export const accidentResponsibilityPageCompleted = createAction<{
  responsible: AccidentResponsibilityCauser;
}>('ACCIDENT_RESPONSIBILITY_PAGE_COMPLETED');

// accident driver information page

export const updateAccidentDriverInformationData = createAction<{
  data: AccidentDriverInformationReducer;
}>('UPDATE_ACCIDENT_DRIVER_INFORMATION_DATA');

export const setAccidentDriverInformationData = createAction<
  Partial<AccidentDriverInformationReducer>
>('SET_ACCIDENT_DRIVER_INFORMATION_DATA');

// otherAccidentPartyPage
export const otherAccidentPartyPageCompleted = createAction<Partial<OtherAccidentPartyReducerData>>(
  'OTHER_ACCIDENT_PARTY_PAGE_COMPLETED',
);

// accident overview page
export const accidentOverviewPageCompleted = createAction<Partial<CompleteAccidentReportInput>>(
  'ACCIDENT_OVERVIEW_PAGE_COMPLETED',
);

// accident report completing status
export const completeAccidentReport = createAction('COMPLETE_ACCIDENT_REPORT');
export const completeAccidentReportSuccess = createAction('COMPLETE_ACCIDENT_REPORT_SUCCESS');
export const completeAccidentReportFailure = createAction<{ error: Error }>(
  'COMPLETE_ACCIDENT_REPORT_FAILURE',
);

// accident step
export const accidentStepCompleted = createAction('ACCIDENT_STEP_COMPLETED');

// accident additional questions page
export const setAccidentAdditionalQuestionsData = createAction<
  Partial<AccidentAdditionalQuestionsReducer>
>('SET_ACCIDENT_ADDITIONAL_QUESTIONS_DATA');

// accident wildlife protection page
export const accidentWildlifeProtectionStepCompleted = createAction<
  Partial<DamageReportWildlifeDataInput>
>('ACCIDENT_WILDLIFE_PROTECTION_STEP_COMPLETED');
