import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { DamageReportWildlifeDataInput } from 'src/api/globalTypes';

import { accidentWildlifeProtectionStepCompleted } from '../actions';

const initialState: DamageReportWildlifeDataInput = {};

export const accidentWildlifeReducer = createReducer(initialState, {
  [accidentWildlifeProtectionStepCompleted.type]: (
    state,
    action: PayloadAction<DamageReportWildlifeDataInput>,
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },
});
