import { useEffect, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Location } from 'react-router-dom';

import { ImageTypes } from 'src/apps/NewDriverApp/constants';
import { ImageState } from 'src/apps/NewDriverApp/redux/types/Types';
import { DamagePhotosCameraContext } from 'src/apps/NewDriverApp/types';
import formatDate from 'src/apps/NewDriverApp/utils/formatDate';
import { getImageInputLabelText } from 'src/apps/NewDriverApp/utils/getImageInputLabelText';

import { i18n_namespace } from '../../i18n-namespace';

export const useImageViewerImages = ({
  images,
  getDescription,
  isRequired = () => false,
}: {
  images: ImageState[];
  isRequired?: (imageType: ImageTypes, i: number) => boolean;
  getDescription?: (imageType: string) => string;
}) => {
  const { t } = useTranslation([i18n_namespace]);

  const mappedImageViewerImages = useMemo(
    () =>
      images.map(
        (
          {
            id,
            internalId,
            fullUrl,
            createdAt,
            context,
            isVisibilityRestricted,
            isDeletable,
            uploadProgress,
          }: ImageState<DamagePhotosCameraContext>,
          i,
        ) => {
          const currentImageType = context?.imageType as ImageTypes;

          const description = getDescription
            ? getDescription(currentImageType)
            : getImageInputLabelText({ t, imageType: currentImageType });

          return {
            id: (internalId ?? id) as string,
            src: fullUrl as string,
            description,
            createdAt: formatDate(createdAt),
            isRequired: isRequired(currentImageType, i),
            isVisibilityRestricted,
            isDeletable,
            uploadProgress,
          };
        },
      ),
    [images],
  );

  return mappedImageViewerImages;
};

export const useScrollToLocation = <T extends Element>(
  location: Location<any>,
  options: ScrollIntoViewOptions,
) => {
  const scrolledRef = useRef<T>(null);

  useEffect(() => {
    const hashAnchor = location.hash;
    if (scrolledRef.current && hashAnchor) {
      scrolledRef.current.scrollIntoView(options);
    }
  }, []);

  return {
    scrolledRef,
  };
};
