import AlbanianFlagIcon from 'src/img/flags/reexports/px-albanian';
import ArabicFlagIcon from 'src/img/flags/reexports/px-arabic';
import BritishFlagIcon from 'src/img/flags/reexports/px-english';
import FrenchFlagIcon from 'src/img/flags/reexports/px-french';
import GermanFlagIcon from 'src/img/flags/reexports/px-german';
import HrvatskiFlagIcon from 'src/img/flags/reexports/px-hrvatski';
import ItalianFlagIcon from 'src/img/flags/reexports/px-italian';
import PolishFlagIcon from 'src/img/flags/reexports/px-polish';
import RomanianFlagIcon from 'src/img/flags/reexports/px-romanian';
import RussianFlagIcon from 'src/img/flags/reexports/px-russian';
import SpanishFlagIcon from 'src/img/flags/reexports/px-spain';
import SrpskiFlagIcon from 'src/img/flags/reexports/px-srpski';
import TurkishFlagIcon from 'src/img/flags/reexports/px-turkish';
import UkrainianFlagIcon from 'src/img/flags/reexports/px-ukrainian';

export const lngOptions = [
  {
    name: 'de',
    nativeName: 'Deutsch',
    icon: GermanFlagIcon,
  },
  {
    name: 'en',
    nativeName: 'English',
    icon: BritishFlagIcon,
  },
  {
    name: 'pl',
    nativeName: 'Polski',
    icon: PolishFlagIcon,
  },
  {
    name: 'tr',
    nativeName: 'Türkçe',
    icon: TurkishFlagIcon,
  },
  {
    name: 'ar',
    nativeName: 'العربية',
    icon: ArabicFlagIcon,
  },
  {
    name: 'es',
    nativeName: 'Español',
    icon: SpanishFlagIcon,
  },
  {
    name: 'ru',
    nativeName: 'Русский',
    icon: RussianFlagIcon,
  },
  {
    name: 'ro',
    nativeName: 'Română',
    icon: RomanianFlagIcon,
  },
  {
    name: 'fr',
    nativeName: 'Français',
    icon: FrenchFlagIcon,
  },
  {
    name: 'it',
    nativeName: 'Italiano',
    icon: ItalianFlagIcon,
  },
  {
    name: 'ua',
    nativeName: 'Українська',
    icon: UkrainianFlagIcon,
  },
  {
    name: 'sq',
    nativeName: 'Shqip',
    icon: AlbanianFlagIcon,
  },
  {
    name: 'hr',
    nativeName: 'Hrvatski',
    icon: HrvatskiFlagIcon,
  },
  {
    name: 'sr',
    nativeName: 'Srpski',
    icon: SrpskiFlagIcon,
  },
];
