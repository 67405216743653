import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { DamageReportImageType } from 'src/api/globalTypes';

import { PHOTO_FLOWS } from '../constants';
import { DamagePhotosCameraContext, PhotoFlow } from '../types';
import { cameraSelector } from '../ui-components/Camera/redux/selectors';

export interface ImageFlowData {
  flowName: PhotoFlow | null;
  imageTypes: DamageReportImageType[] | null;
}

const useGetCurrentImageFlowData = (): ImageFlowData => {
  const { selectedPhoto } = useSelector(cameraSelector) || {};
  const cameraContext = get(selectedPhoto, 'context', {}) as DamagePhotosCameraContext;
  const { imageType } = cameraContext;

  const flowEntry = Object.entries(PHOTO_FLOWS).find(([, imageTypes]) => {
    return imageTypes.includes(imageType);
  });

  if (flowEntry) {
    return {
      flowName: flowEntry[0] as PhotoFlow,
      imageTypes: flowEntry[1],
    };
  }

  return {
    flowName: null,
    imageTypes: null,
  };
};

export default useGetCurrentImageFlowData;
