import { combineReducers } from '@reduxjs/toolkit';

import { accidentAdditionalQuestionsReducer } from './accident-additional-questions-reducer';
import { accidentCarConditionReducer } from './accident-car-condition-reducer';
import { accidentDriverInformationReducer } from './accident-driver-information-reducer';
import { accidentFixedObjectInformationReducer } from './accident-fixed-object-information-reducer';
import { accidentInjuredPersonInformationReducer } from './accident-injured-person-information-reducer';
import { accidentPoliceReportReducer } from './accident-police-report-reducer';
import { accidentSceneReducer } from './accident-scene-reducer';
import { accidentSecrecyObligationReducer } from './accident-secrecy-obligation-reducer';
import { accidentWildlifeReducer } from './accident-wildlife-protection-reducer';
import { otherAccidentPartyReducer } from './other-accident-party-reducer';
import { saveAccidentReportReducer } from './save-accident-report-reducer';

export const accidentReportReducer = combineReducers({
  addtionalQuestions: accidentAdditionalQuestionsReducer,
  accidentScene: accidentSceneReducer,
  accidentDriverInformation: accidentDriverInformationReducer,
  accidentWildlife: accidentWildlifeReducer,
  carCondition: accidentCarConditionReducer,
  policeReport: accidentPoliceReportReducer,
  injuredPersonInformation: accidentInjuredPersonInformationReducer,
  otherAccidentParty: otherAccidentPartyReducer,
  fixedObjectInformation: accidentFixedObjectInformationReducer,
  savingStatus: saveAccidentReportReducer,
  secrecyObligation: accidentSecrecyObligationReducer,
});

export type AccidentReportReducer = ReturnType<typeof accidentReportReducer>;
