import { createAction } from '@reduxjs/toolkit';

import { DriverInformation } from 'src/api/globalTypes';
import { ImageState, ImageFromCamera } from 'src/apps/NewDriverApp/redux/types/Types';
import { DamagePhotosCameraContext } from 'src/apps/NewDriverApp/types';

import { ServiceLightsFormModel, ServiceLightsReportSavingStatus } from '../types';

export const setServiceLightsPhoto = createAction<ImageState<DamagePhotosCameraContext>>(
  'SET_SERVICE_LIGHTS_PHOTO',
);

export const serviceLightsImageUploadStart = createAction<{ internalId: string }>(
  'SERVICE_LIGHTS_IMAGE_UPLOAD_START',
);

export const serviceLightsImageUploadProgressUpdate = createAction<{
  internalId: string;
  uploadProgress: number;
}>('SERVICE_LIGHTS_IMAGE_UPLOAD_PROGRESS_UPDATE');

export const serviceLightsImageUploadSuccess = createAction<{
  image: ImageState;
  id: string;
  fullUrl: string;
}>('SERVICE_LIGHTS_IMAGE_UPLOAD_SUCCESS');

export const serviceLightsImageUploadFailure = createAction<{ image: ImageState; error: Error }>(
  'SERVICE_LIGHTS_IMAGE_UPLOAD_FAILURE',
);

export const serviceLightsImageInputSelect = createAction<ImageFromCamera>(
  'SERVICE_LIGHTS_IMAGE_INPUT_SELECT',
);

export const serviceLightsDashboardCompleted = createAction<{ data: ServiceLightsFormModel }>(
  'SERVICE_LIGHTS_DASHBOARD_COMPLETED',
);

export const updateServiceLightsDriverInformation = createAction<{ data: DriverInformation }>(
  'UPDATE_SERVICE_LIGHTS_DRIVER_INFORMATION',
);

export const setServiceLightsDriverInformation = createAction<DriverInformation>(
  'SET_SERVICE_LIGHTS_DRIVER_INFORMATION',
);

export const completeServiceLightsReport = createAction('COMPLETE_SERVICE_LIGHTS_REPORT');

export const updateServiceLightsReportSavingStatus = createAction<{
  data: Partial<ServiceLightsReportSavingStatus>;
}>('UPDATE_SERVICE_LIGHTS_REPORT_SAVING_STATUS');

export const removeServiceLightsImage = createAction<{ internalId: string }>(
  'REMOVE_SERVICE_LIGHTS_IMAGE',
);

export const startServiceLightsImageUpload = createAction<ImageFromCamera>(
  'START_SERVICE_LIGHTS_IMAGE_UPLOAD',
);
