import { TFunction } from 'i18next';

import { ImageTypes } from '../constants';

export const getImageInputLabelText = ({
  t,
  imageType,
}: {
  t: TFunction;
  imageType: ImageTypes;
}) => {
  return t(`common:image.${imageType}.label`, { defaultValue: '' });
};
