import React, { ReactElement } from 'react';

import cn from 'classnames';

import styles from './PhotoInputGridItem.module.scss';

const PhotoInputGridItem: React.FC<{ children: ReactElement }> = ({ children }) => (
  <div className={styles.inputContainer}>
    {React.cloneElement(children, { className: cn(styles.input, children?.props?.className) })}
  </div>
);

export default PhotoInputGridItem;
