import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
  accidentResponsibilityPageCompleted,
  otherAccidentPartyPageCompleted,
} from 'src/apps/NewDriverApp/flows/fleet/accident/redux/actions';

export interface OtherAccidentPartyReducerData {
  licensePlate?: string;
  responsible?: string;
  personName?: string | null;
  insurance?: string | null;
  comment?: string | null;
}

export interface OtherAccidentPartyReducer {
  otherAccidentPartyInformation: OtherAccidentPartyReducerData;
}

const initialState: OtherAccidentPartyReducer = {
  otherAccidentPartyInformation: {
    responsible: '',
    insurance: '',
    personName: '',
    licensePlate: '',
    comment: '',
  },
};

export const otherAccidentPartyReducer = createReducer(initialState, {
  [otherAccidentPartyPageCompleted.type]: (state, { payload }) => {
    state.otherAccidentPartyInformation = {
      ...state.otherAccidentPartyInformation,
      ...payload,
    };
  },
  [accidentResponsibilityPageCompleted.type]: (
    state,
    action: PayloadAction<OtherAccidentPartyReducerData>,
  ) => {
    state.otherAccidentPartyInformation = {
      ...state.otherAccidentPartyInformation,
      responsible: action.payload.responsible,
    };
  },
});
