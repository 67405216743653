export const ACCIDENT_FLOW_PAGE_NAME = {
  CAR_CONDITION: 'carCondition',
  DAMAGE_REPORT: 'damageReport',
  CAR_OVERVIEW_PHOTOS: 'carOverviewPhotos',
  ACCIDENT_SCENE: 'accidentScene',
  INJURED_PERSON_INFO: 'injuredPersonInfo',
  OTHER_ACCIDENT_PARTY: 'otherAccidentParty',
  ACCIDENT_RESPONSIBILITY: 'accidentResponsibility',
  DAMAGED_OBJECT: 'damagedObject',
  WILDLIFE_PROTECTION: 'wildlifeProtection',
  POLICE_REPORT: 'policeReport',
  ACCIDENT_DOCUMENTS: 'accidentDocuments',
  DRIVER_INFORMATION: 'driverInformation',
  ADDITIONAL_QUESTIONS: 'additionalQuestions',
  ACCIDENT_OVERVIEW: 'accidentOverview',
  SECRECY_OBLIGATION: 'secrecyObligation',
} as const;
