import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { updateDamagedObjectData } from '../actions';
import { DamageReportFixedObjectReducer } from '../types';

const initialState: DamageReportFixedObjectReducer = {
  damagedObjectDescription: '',
};

export const accidentFixedObjectInformationReducer = createReducer(initialState, {
  [updateDamagedObjectData.type]: (
    state,
    action: PayloadAction<DamageReportFixedObjectReducer>,
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },
});
