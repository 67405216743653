import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './ImageVisibilitySelector.module.scss';
import { i18n_namespace } from '../../../../i18n-namespace';
import ImageVisibilityOption from '../ImageVisibilityOption';
import { VisibilityStatus } from '../types';
import { getVisibilityOptions } from '../utils';

interface Props {
  onChange?: (isVisibilityRestricted: boolean) => void;
  isVisibilityRestricted?: boolean | null;
}

const ImageVisibilitySelector: React.FC<Props> = ({ onChange, isVisibilityRestricted = false }) => {
  const { t } = useTranslation(i18n_namespace);
  const visibilityOptions = getVisibilityOptions(t);

  const changeStatus = (visibilityStatus: VisibilityStatus) => {
    if (onChange) {
      onChange(visibilityStatus === VisibilityStatus.HIDDEN);
    }
  };

  const currentVisibilityStatus = isVisibilityRestricted
    ? VisibilityStatus.HIDDEN
    : VisibilityStatus.PUBLIC;

  return (
    <div className={styles.listGroup}>
      {visibilityOptions.map(({ title, icon, visibilityStatus }) => {
        return (
          <ImageVisibilityOption
            key={title}
            title={title}
            onClick={() => changeStatus(visibilityStatus)}
            isChecked={visibilityStatus === currentVisibilityStatus}
            Icon={icon}
          />
        );
      })}
    </div>
  );
};

export default ImageVisibilitySelector;
