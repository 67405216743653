import React from 'react';

interface Props {
  className?: string;
}

const CloseImageViewer: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="px-close-large"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.69208 0.290314C1.30499 -0.0967715 0.6774 -0.0967712 0.290314 0.290314C-0.096771 0.6774 -0.0967717 1.30499 0.290314 1.69207L6.59824 8L0.290315 14.3079C-0.096771 14.695 -0.0967714 15.3226 0.290314 15.7097C0.6774 16.0968 1.30499 16.0968 1.69208 15.7097L8 9.40176L14.3079 15.7097C14.695 16.0968 15.3226 16.0968 15.7097 15.7097C16.0968 15.3226 16.0968 14.695 15.7097 14.3079L9.40176 8L15.7097 1.69208C16.0968 1.30499 16.0968 0.677401 15.7097 0.290315C15.3226 -0.096771 14.695 -0.0967698 14.3079 0.290316L8 6.59824L1.69208 0.290314Z"
        fill="#CBCCD6"
      />
    </svg>
  );
};

export default CloseImageViewer;
